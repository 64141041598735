import React from 'react'
import { Button } from "@nextui-org/react";
import { Text } from "@nextui-org/react";
import heroImage from '../img/soft_layout.png'
function Home() {
    const loadTool = () => {
        window.location.href = '/tool'
    }
    return (
        <div className='flex flex-col items-center justify-between w-screen min-h-screen bg-[#0a0a0a]'>
            <div className="flex flex-row w-full items-center justify-center">
                <Text color='$white' size={'$7xl'} className='font-bold'><span className='text-blue-500'>Z</span>nap.</Text>
            </div>
            <div className="flex flex-col h-full w-11/12 md:w-10/12 items-center justify-center ">
                <div className="flex flex-col lg:flex-row w-11/12 p-4 md:p-12 items-center justify-between">
                    <div className="flex flex-col items-center lg:items-start justify-center md:w-4/6">
                    <Text color='$white' h2 className='text-3xl lg:text-5xl text-center lg:text-left md:w-5/6 mb-8'>You have the idea, we have the tools. Let Znap help you turn your business dream into a reality. 🥳</Text>
                    <Text h5 color='$white' className='text-center lg:text-left opacity-70 md:w-4/6 font-normal text-md md:text-xl mb-8'>We will generate a unique business name, draft a winning business plan, design a stunning website, and develop your product.</Text>
                    <Button onPress={loadTool} size={'lg'} auto>Get Started 👉</Button>
                    </div>
                    <div className="flex flex-col justify-center mt-12 mb-12 lg:mt-0 lg:w-5/12 h-2/5">
                        <img src={heroImage} className='object-fill mb-5' />
                    </div>
                </div>
            </div>
            {/* Footer */}
            <div className="flex flex-row w-full py-2 items-center justify-center bg-[#141414]">
                <Text b color='$white' size={'$sm'}>Znap 2023</Text>
            </div>
        </div>
    )
}

export default Home