import * as React from 'react';
// 1. import `NextUIProvider` component
import { NextUIProvider } from '@nextui-org/react';
import Home from './pages/Home';
import Tool from './pages/Tool';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  // 2. Use at the root of your app
  return (
    <NextUIProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/tool" component={Tool} />
        </Switch>
      </Router>
    </NextUIProvider>
  );
}

export default App;